/** @jsx jsx */
import { jsx } from "theme-ui";
import { useCallback, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Icon from "./Buttons/Icon";

const EveryDropCountsSlider = () => {
  const [index, setIndex] = useState(0);
  const { allFile } = useStaticQuery(
    graphql`
      {
        allFile(filter: { name: { regex: "/drop-count-slider-img/" } }) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(quality: 100, maxWidth: 450) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  );

  const length = allFile.edges.length - 1;
  const handleNext = useCallback(
    () => setIndex((i) => (i === length ? 0 : i + 1)),
    [length]
  );
  const handlePrevious = useCallback(
    () => setIndex((i) => (i === 0 ? length : i - 1)),
    [length]
  );

  if (!allFile.edges[index]) return null;

  const { node } = allFile.edges[index];

  return (
    <div
      sx={{
        width: ["100%", "100%"],
        float: "left",
        margin: "0px 20px 10px 0px",
        height: "100%",
        justifyContent: "center",
        display: "inline-flex",
        marginTop: "100px",
      }}
    >
      <BackgroundImage
        sx={{ height: "100%", width: ["90%", "90%", "60%"] }}
        Tag="section"
        fluid={node.childImageSharp.fluid}
      >
        <div sx={{ top: "40%", position: "absolute", display: "none" }}>
          <Icon
            sx={{ m: 10, cursor: "pointer", color: "#ffff" }}
            iconName="circle-left"
            onClick={() => handlePrevious()}
            size="3x"
          />
        </div>
        <div
          sx={{ top: "40%", position: "absolute", right: 0, display: "none" }}
        >
          <Icon
            sx={{ m: 10, cursor: "pointer", color: "#ffff" }}
            iconName="circle-right"
            onClick={() => handleNext()}
            size="3x"
          />
        </div>
      </BackgroundImage>
    </div>
  );
};

export default EveryDropCountsSlider;
